import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, Grid, Menu, MenuItem, ClickAwayListener, 
    Grow, Popper, MenuList, Paper, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import ihsLogo from './images/ihs.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAPIUrl, handleAxiosError } from './AppUtils';
import axios from 'axios';
import NavHoverMenu from './NavHoverMenu';

const styles = theme => ({
    root: {
        // flexGrow: 1,
    },
    grow: {
        flexGrow: 1,
    },
    logo: {
        paddingLeft: 24,
        paddingTop: 20,
        maxWidth: 442,
        height: "auto",
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    button: {
        color: "white",
        '&:hover': {
            color: theme.palette.secondary.main,
        },       
    },
    buttonGroup: {
        marginTop: 30,
        marginRight: 0,
    },
    accountName: {
        marginLeft: 30,
        marginRight: -4,
    },
    menuItem: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
      height: 15,
    },
    primary: {},
    icon: {},
    menu: {
        zIndex: 4,
    },
});

function NavigationBar(props) {
    const { classes, user, adminView, setAdminView, setUser, orgSettings, history } = props;
    const [open, setOpen] = useState(false);
    const anchorEl = useRef(null);
    function handleToggle() {
      setOpen(!open);
    }
    function handleClose(event) {
      if (anchorEl.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    }
    function handleLogout() {
        axios.post(getAPIUrl() + '/logoutURL', { 'hostname': window.location.hostname, 'redirect': window.location.origin+'/loggedout' })
        .then(response => {
            setOpen(false);
            localStorage.removeItem("intelhsApiToken")
            setUser({
                authenticated:false,
                id: undefined,
                type: undefined,
                first_name: undefined,
            })
            window.location.href = response.data
        })
        .catch(error=>handleAxiosError(error));
    }
    function toggleAdminView() {
        setOpen(false);
        if(adminView){
            props.history.push('/start')
        }
        else{
            props.history.push('/event')
        }
        setAdminView(!adminView);
    }
    function goToManagement() {
        setOpen(false);
        props.history.push('/settings')
    }
    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Grid container direction="row">
                        <Grid item md={4}>
                            <img className={classes.logo}
                                src={ihsLogo}
                                alt=''
                            />
                        </Grid>
                        <Grid container item md={4} alignItems="center" justify="center">
                            <Typography align="center" variant="h5">Status Report (Stat Rep)</Typography>
                        </Grid>
                        <Grid item md={4} className={classes.buttonGroup} container alignItems='center' justify='flex-end'>
                            {adminView?
                                <React.Fragment>
                                    {orgSettings.strikeDashboard || orgSettings.quickStatRep?
                                        <React.Fragment>
                                            <NavHoverMenu orgSettings={orgSettings}/>
                                            {/* <Button component={Link} to="/strikeDashboard" color="secondary" size="large" className={classes.button}>
                                                Strike Dashboard
                                            </Button>
                                            <Button component={Link} to="/strikeEvents" color="secondary" size="large" className={classes.button}>
                                                Strike Events
                                            </Button> */}
                                        </React.Fragment>:
                                        <React.Fragment>
                                            <Button component={Link} to="/event" color="secondary" size="large" className={classes.button}>
                                                Dashboard
                                            </Button>
                                            <Button component={Link} to="/report-list" color="secondary" size="large" className={classes.button}>
                                                Reports
                                            </Button>
                                            <Button component={Link} to="/eventsSummary" color="secondary" size="large" className={classes.button}>
                                                Events
                                            </Button>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                                :<React.Fragment>
                                    <Button component={Link} to="/start" color="secondary" size="large" className={classes.button}>
                                        New
                                    </Button>
                                    <Button component={Link} to="/report-list" color="secondary" size="large" className={classes.button}>
                                        {orgSettings.strikeDashboard?"StatRep Reports":"Reports"}
                                    </Button>
                                    {orgSettings.strikeDashboard?
                                        <Button component={Link} to="/strikeReports" color="secondary" size="large" className={classes.button}>
                                            Strike Reports
                                        </Button>:undefined
                                    }
                                    {orgSettings.quickStatRep?
                                        <Button component={Link} to="/quickStatReps" color="secondary" size="large" className={classes.button}>
                                            Quick StatReps
                                        </Button>:undefined
                                    }
                                    {orgSettings.helpTab?
                                        <Button component={Link} to="/help" color="secondary" size="large" className={classes.button}>
                                            Help
                                        </Button>:undefined
                                    }
                                </React.Fragment>
                            }
                            {orgSettings.locationsTab?
                                <Button component={Link} to="/locations" color="secondary" size="large" className={classes.button}>
                                    Locations
                                </Button>:undefined
                            }
                            <Typography color="inherit" variant="subtitle1" className={classes.accountName}>
                                {user.first_name}
                            </Typography>
                            <IconButton color="inherit" 
                                className={classes.iconButton} 
                                onClick={handleToggle}
                                buttonRef={anchorEl}
                            >
                                <AccountCircleIcon className={classes.smallIcon} />
                            </IconButton>
                            <Popper className={classes.menu} open={open} anchorEl={anchorEl.current} disablePortal>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        {user.type>0?
                                            <MenuItem className={classes.menuItem} onClick={toggleAdminView}>
                                                <ListItemIcon className={classes.icon}>
                                                    <FontAwesomeIcon size="sm" icon={["fa","asterisk"]}/>
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} 
                                                    primary={adminView?"Non Admin":"Admin"}
                                                />
                                            </MenuItem>
                                        :undefined}
                                        {user.type===3?
                                            <MenuItem className={classes.menuItem} onClick={goToManagement}>
                                                <ListItemIcon className={classes.icon}>
                                                    <FontAwesomeIcon size="sm" icon={["fa","cog"]}/>
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} 
                                                    primary={"Settings"}
                                                />
                                            </MenuItem>
                                        :undefined}
                                        <MenuItem className={classes.menuItem} onClick={handleLogout}>
                                            <ListItemIcon className={classes.icon}>
                                                <FontAwesomeIcon size="sm" icon={["fa","sign-out-alt"]}/>
                                            </ListItemIcon>
                                            <ListItemText classes={{ primary: classes.primary }} 
                                                primary="Sign Out"
                                            />
                                        </MenuItem>
                                    </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

NavigationBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NavigationBar);